import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { IoCloseOutline } from 'react-icons/io5';
import LoadContent from '../../../../../components/Load-content';
import TextualGenreTagsApi from '../../../../../class/new-writing-hypothesi/textual-genre-tags/textual-genre-tags-api';
import { useParams } from 'react-router-dom';
import { type TextualGenreTagType, type TextualGenreType } from '../../../../../class/types/new-writing-hypothesi/textual-genre';
import TextualGenreTagsCostumer from '../../../../../class/new-writing-hypothesi/textual-genre-tags/textual-genre-tags-costumer';

interface TextualGenreFormAddTagProps {
  display: boolean
  roomInfo: any
  textualGenreSelected: TextualGenreType | null
  textualGenreTagSelected: TextualGenreTagType | null
  setTextualGenreTags: React.Dispatch<React.SetStateAction<TextualGenreTagType[]>>
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

export default function TextualGenreFormEditTag({ display, roomInfo, textualGenreSelected, textualGenreTagSelected, setDisplay, setTextualGenreTags }: TextualGenreFormAddTagProps): React.ReactElement {
  const [color, setColor] = useState('#000');
  const [isLoading, setIsLoading] = useState(false);
  const inputTag = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState(false);

  const {id} = useParams();

  const huddleInputColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    setColor(target.value);
  }

  const displayError = () => {
    if (error) {
      return;
    }

    setError(true);

    setTimeout(() => {
      setError(false);
    }, 3000);
  }

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!id || !textualGenreSelected || !inputTag.current || !textualGenreTagSelected?.id) {
      return;
    }

    if (inputTag.current.value.length === 0) {
      displayError();
      return;
    }

    const textualGenreTagCostumer = new TextualGenreTagsCostumer();

    const data: TextualGenreTagType = {
      color,
      tag: inputTag.current.value,
    }

    setIsLoading(true);
    textualGenreTagCostumer.update(id, roomInfo.twoMonths, textualGenreSelected.id, textualGenreTagSelected?.id, data.tag, data.color).then(response => {
      setTextualGenreTags(response);
      setDisplay(false);
      setIsLoading(false);

      if (!inputTag.current) {
        return;
      }
      inputTag.current.value = '';
    }).catch(e => {
      //
    })
  }

  const close = () => {
    if (!inputTag.current) {
      return;
    }

    inputTag.current.value = '';
    setDisplay(false);
  }

  useEffect(() => {
    if (!inputTag.current) {
      return;
    }

    inputTag.current.value = textualGenreTagSelected?.tag ?? '';
    setColor(textualGenreTagSelected?.color ?? '#000');
  }, [display])

  return (
    <div className={`textual-genre-form-edit-tag display-${String(display)}`}>
      <div className='header-form-edit-textual-genre-tag'>
        <h1>
          Editar Nível
        </h1>
        <IoCloseOutline className='icon-close' onClick={() => {
          setDisplay(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='content-form-edit-textual-genre-tag-main' onSubmit={huddleSubmit}>
        <div className='content-form-edit-textual-genre-tag-main-inputs' >
          <div className='form-edit-textual-genre-tag-input-content'>
            <input type="text" placeholder='Digite o nível' ref={inputTag} />
          </div>
          <div className='form-edit-textual-genre-tag-input-content'>
            <input type="color" id='textual-genre-form-edit-input-color' onChange={huddleInputColor} />
            <label className='label-input-color' htmlFor="textual-genre-form-edit-input-color" >
              <div className='label-input-color-div' style={{
                backgroundColor: color,
              }}></div>
            </label>
          </div>
        </div>
        <div className='content-form-edit-textual-genre-error'>
            <span className={`error-element display-${String(error)}`} >Preencha o campo!</span>
          </div>
        <div className='form-edit-textual-genre-tag-button-content'>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            )
            : (
              <>
                <button type='button' className='button-element button-cancel' onClick={close}>Cancelar</button>
                <button type='submit' className='button-element button-add'>Editar</button>
              </>
            )}
        </div>
      </form>
    </div>
  );
}
