
import Validator from '../Validator';
import { type StudentReportType } from '../types/StudentReport';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import UploadKindergartenSchoolJustificationForOneAbsenceApi from './upload-kindergarten-school-justification-for-one-absence-api'
import lscache from 'lscache';

class UpLoadKindergartenJustificationForOneAbsenceCostumer {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement> | undefined = undefined,
		private readonly JustificationId = '',
		private readonly textArea: HTMLTextAreaElement | undefined = undefined
	) {}

	get _user(): UserClientProtocol {
		const user = lscache.get('user') as UserClientProtocol;

		return user;
	}

	async post(): Promise<any> {
		if (!this.inputs) {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: true
			};
		}

		const file = this.inputs.item(3).files?.item(0);

		if (!file) {
			return {
				error: true
			};
		}

		const fileName = file.name ?? '';
		const date = this.inputs.item(2).value;
    const twoMonths = this.inputs.item(1).value;
    const classRoom = this.inputs.item(0).value;

		const user = this._user;

		let justificationForOneAbsence: StudentReportType[] = [];

		await UploadKindergartenSchoolJustificationForOneAbsenceApi.post({
			file,
      classRoom,
      twoMonths,
			date,
			name: fileName,
			userId: user.id ?? '',
			userName: user.userName
		}).then(response => {
			justificationForOneAbsence = response;
		}).catch(e => {
			//
		});

		return justificationForOneAbsence;
	}

	async put(): Promise<any> {
		if (!this.inputs) {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: false
			};
		}

		const file = this.inputs.item(0).files?.item(0);

		if (!file) {
			return {
				error: false
			};
		}

		const fileName = file.name ?? '';

		let justificationForOneAbsence: StudentReportType[] = [];

		await UploadKindergartenSchoolJustificationForOneAbsenceApi.put({
			file,
			name: fileName,
			studentReportId: this.JustificationId,
		}).then(response => {
			justificationForOneAbsence = response;
		});

		return justificationForOneAbsence;
	}

  async postDescription(): Promise<StudentReportType | undefined> {
		if (!this.textArea) {
			return;
		}

		if (!this.textArea?.value) {
			Validator.displayError(this.textArea, 'o campo esta vazio');
			return;
		}

		const description = this.textArea.value;
		let newElementarySchoolWeeklyPlan: StudentReportType | undefined;

		await UploadKindergartenSchoolJustificationForOneAbsenceApi.postDescription(this.JustificationId, description, this._user.id ?? '', this._user.userName).then(response => {
			newElementarySchoolWeeklyPlan = response;
		});

		return newElementarySchoolWeeklyPlan;
	}
}

export default UpLoadKindergartenJustificationForOneAbsenceCostumer;
