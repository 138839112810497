/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useEffect, useState } from 'react';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import { useParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import LoadContent from '../../Load-content';
import lscache from 'lscache';
import './style.scss';
import type MunicipalEducationPlanMonitoringSheetType from '../../../class/types/municipal-education-plan-monitoring-sheet';
import { FiEdit2 } from 'react-icons/fi';
import MunicipalEducationPlanMonitoringSheetApi from '../../../class/municipal-education-plan-monitoring-shet/municipal-education-plan-monitoring-sheet-api';

interface ContentTableType {
  municipalEducationPlanMonitoringSheet: MunicipalEducationPlanMonitoringSheetType
  index: number
  setMunicipalEducationPlanMonitoringSheet: React.Dispatch<React.SetStateAction<MunicipalEducationPlanMonitoringSheetType[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setElement: React.Dispatch<React.SetStateAction<MunicipalEducationPlanMonitoringSheetType | undefined>>
}

export default function ContentTableMunicipalEducationPlanMonitoringSheet({ municipalEducationPlanMonitoringSheet, index, setElement, setDisplayEditElement, setMunicipalEducationPlanMonitoringSheet }: ContentTableType): React.ReactElement {
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserClientProtocol>();
  const { id } = useParams();

  const hundleDelete = () => {
    if (municipalEducationPlanMonitoringSheet.id) {
      setIsLoading(true);

      const municipalEducationPlanMonitoringSheetApi = new MunicipalEducationPlanMonitoringSheetApi();
      municipalEducationPlanMonitoringSheetApi.delete(municipalEducationPlanMonitoringSheet.id).then(response => {
        setMunicipalEducationPlanMonitoringSheet(response);
        setIsLoading(false);
      });
    }
  };

  const huddleEdit = () => {
    setElement(municipalEducationPlanMonitoringSheet);
    setDisplayEditElement(true);
  }

  const huddleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    window.location.href = window.location.origin + '/ficha-de-monitoramento-do-plano-municipal-de-educacao/' + (municipalEducationPlanMonitoringSheet.id ?? '');
  }

  useEffect(() => {
    const user = lscache.get('user');
    if (user) {
      setUser(user);
    }
  }, []);

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [municipalEducationPlanMonitoringSheet]);

  return (
    <div className={`content-table-element-municipal-education-plan-municipal-sheet display-animation-${displayAnimation}`} onClickCapture={huddleClick}>
      <ul className='content-table-ul-rom'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            FICHA DE MONITORAMENTO DO PLANO MUNICIPAL DE EDUCAÇÃO
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {municipalEducationPlanMonitoringSheet.county}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {municipalEducationPlanMonitoringSheet.yearOfFirstAssessment}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {municipalEducationPlanMonitoringSheet.issuer.date}
          </h2>
        </li>
        <li className='content-table-ul-li' onClick={huddleEdit}>
            <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {user
          ? ((user.permission?.rule === 'coordenador' || user.permission?.rule === 'console')
            ? (
              isLoading
                ? (
                  <li>
                    <LoadContent
                      isLoading={isLoading}
                      style={{
                        button: {
                          fontSize: 20,
                          color: '#ff0000'
                        },
                        container: {
                          width: 20,
                        }
                      }}
                    />
                  </li>
                )
                : (
                  <li className='header-table-ul-li' onClick={hundleDelete}>
                    <MdDelete size='20' style={{ color: '#ff0000' }} />
                  </li>
                ))
            : (<li> </li>))
          : (<li></li>)}
      </ul>
    </div>
  );
}
