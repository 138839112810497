/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useState, useEffect } from 'react';
import './App.scss';
import Header from '../../components/Header';
import Nav from '../../components/strategy-files/Nav-home';
import Strategy from '../../container/strategy-files/Strategy';
import Coordinator from '../../container/coordinators-files/coordinator';
import Teachers from '../../container/teacher-files/teacher';
import Rooms from '../../container/rooms/rooms';
import lscache from 'lscache';
import Students from '../../container/student-files/Student';
import Plan from '../../container/plan-files/Plan';
import RoomsTeacher from '../../container/rooms-teacher/rooms';
import ConfigUser from '../../container/config-user';
import WritingLevelRecordCoordinatorContent from '../../container/writing-level-record-coordinator/Coordinator';
import ElementarySchoolTeachingWorkPlan from '../../container/elementary-school-lteaching-work-plan/elementary-school-teaching-work-plan';
import KindergartenSchoolTeachingWorkPlan from '../../container/kindergarten-school-lteaching-work-plan/kindergarten-school-teaching-work-plan';
import NewKindergartenSchoolMonthlyPlan from '../../container/new-kindergarten-school-monthly-plan/kindergarten-school-monthly-plan';
import NewElementarySchoolMonthlyPlan from '../../container/new-elementary-school-monthly-plan/kindergarten-school-monthly-plan';
import KindergartenSchoolWeeklyPlan from '../../container/kindergarten-school-weekly-plan/kindergarten-school-weekly-plan';
import ElementarySchoolWeeklyPlan from '../../container/elementary-school-weekly-plan/elementary-school-weekly-plan';
import LoadContent from '../../components/Load-content';
import ReadingFluency from '../../container/reading-fluency/reading-fluency';
import ElementarySchoolHTTPI from '../../container/elementary-school-HTTPI/elementary-school-HTTPI';
import KindergartenSchoolHTTPI from '../../container/kindergarten-school-HTTPI/kindergarten-school-HTTPI';
import DigitalHTTPI from '../../container/kindergarten-digital-HTTPI/digital-HTTPI';
import ElementaryDigitalHTTPI from '../../container/elementary-digital-HTTPI/digital-HTTPI';
import KindergartenSchoolStudentReport from '../../container/kindergarten-school-student-report/kindergarten-student-report';
import ElementarySchoolStudentReport from '../../container/elementary-school-student-report/elementary-student-report';
import KindergartenSchoolATA from '../../container/kindergarten-school-ATA/kindergarten-ATA';
import NotificationManager from '../../container/notification-manager/notification-container';
import BellContainer from '../../container/BellNotification/bell-container';
import StudentMonetaryAid from '../../container/student-monetary-aid/student-monetary-aid';
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol';
import WorkshopHTTPI from '../../container/workshop-HTTPI/workshop-HTTPI';
import WorkshopDigitalHTTPI from '../../container/workshop-digital-HTTPI/digital-HTTPI';
import WorkshopMonthlyPlan from '../../container/workshop-school-monthly-plan/workshop-monthly-plan';
import WorkshopWeeklyPlan from '../../container/workshop-weekly-plan/kindergarten-workshop-school-weekly-plan';
import WorkshopStudentReport from '../../container/workshop-school-student-report/workshop-student-report';
import ElementarySchoolProjects from '../../container/elementary-school-projects/elementary-school-projects';
import AddNewNutritionists from '../../container/add-new-nutritionists-files/add-new-nutritionists';
import AdmLegislation from '../../container/adm-legislation/adm-legislation';
import AdmATA from '../../container/adm-ATA/adm-ATA';
import AdmSlides from '../../container/adm-slides/adm-slides';
import AdmHours from '../../container/adm-hours/adm-hours';
import AdmCalendar from '../../container/adm-calendar/adm-calendar';
import MonitoringPlanSheetEducation from '../../container/municipal-education-plan-monitoring-sheet-home/municipal-education-plan-monitoring-sheet-home';
import PsychologistAddPermission from '../../container/psychologist-add-permission/psychologist-add-permission';
import PsychologistReportHome from '../../container/psychologist-report-home/psychologist-report';
import MathematicsDiagnosticAssessmentSecretarySecretary from '../../container/mathematics-diagnostic-assessment-secretary/Coordinator';
import StudentExams from '../../container/student-exams/student-exams';
import WaitingStudents from '../../container/waiting-student/Student';
import NewSecretaryWritingHypothesis from '../../container/new-writing-hypothesis/secretary-writing-hypothesis/secretary-writing-hypothesis';
import SecretaryDocument from '../../container/Secretary-documents/secretary-documents';
import ResourceManagement from '../../container/resource-management/resource-management';
import EthnicityChart from '../../container/ethnicity-chart';
import EthnicityChartGeneral from '../../container/ethnicity-chart-general';
import ElementarySchoolJustificationForOneAbsence from '../../container/elementary-school-justification-for-one-absence/justification-for-one-absence';
import KindergartenSchoolJustificationForOneAbsence from '../../container/kindergarten-school-justification-for-one-absence/justification-for-one-absence';
import WorkshopJustificationForOneAbsence from '../../container/workshop-justification-for-one-absence/justification-for-one-absence';

type Elements = Array<{
  name: string
  boolean: string
}>;

function App() {
  const user = lscache.get('user') as UserClientProtocol;
  const [elements, setElements] = useState([
    {
      name: 'strategy-1',
      boolean: 'false'
    },
    {
      name: 'strategy-2',
      boolean: 'false'
    },
    {
      name: 'strategy-3',
      boolean: 'false'
    },
    {
      name: 'strategy-4',
      boolean: 'false'
    },
    {
      name: 'strategy-5',
      boolean: 'false'
    },
    {
      name: 'strategy-6',
      boolean: 'false'
    },
    {
      name: 'strategy-7',
      boolean: 'false'
    },
    {
      name: 'strategy-8',
      boolean: 'false'
    },
    {
      name: 'strategy-9',
      boolean: 'false'
    },
    {
      name: 'strategy-10',
      boolean: 'false'
    },
    {
      name: 'strategy-11',
      boolean: 'false'
    },
    {
      name: 'strategy-12',
      boolean: 'false'
    },
    {
      name: 'strategy-13',
      boolean: 'false'
    },
    {
      name: 'strategy-14',
      boolean: 'false'
    },
    {
      name: 'strategy-15',
      boolean: 'false'
    },
    {
      name: 'strategy-16',
      boolean: 'false'
    },
    {
      name: 'strategy-17',
      boolean: 'false'
    },
    {
      name: 'strategy-18',
      boolean: 'false'
    },
    {
      name: 'strategy-19',
      boolean: 'false'
    },
    {
      name: 'strategy-20',
      boolean: 'false'
    },
    {
      name: 'strategy-21',
      boolean: 'false'
    },
    {
      name: 'strategy-22',
      boolean: 'false'
    },
    {
      name: 'strategy-23',
      boolean: 'false'
    },
    {
      name: 'strategy-24',
      boolean: 'false'
    },
    {
      name: 'strategy-25',
      boolean: 'false'
    },
    {
      name: 'strategy-26',
      boolean: 'false'
    },
    {
      name: 'strategy-27',
      boolean: 'false'
    },
    {
      name: 'strategy-28',
      boolean: 'false'
    },
    {
      name: 'strategy-29',
      boolean: 'false'
    },
    {
      name: 'strategy-30',
      boolean: 'false'
    },
    {
      name: 'strategy-31',
      boolean: 'false'
    },
    {
      name: 'strategy-32',
      boolean: 'false'
    },
    {
      name: 'strategy-33',
      boolean: 'false'
    },
    {
      name: 'strategy-34',
      boolean: 'false'
    },
    {
      name: 'strategy-35',
      boolean: 'false'
    },
    {
      name: 'strategy-36',
      boolean: 'false'
    },
    {
      name: 'strategy-37',
      boolean: 'false'
    },
    {
      name: 'strategy-38',
      boolean: 'false'
    },
    {
      name: 'strategy-39',
      boolean: 'false'
    },
    {
      name: 'strategy-40',
      boolean: 'false'
    },
    {
      name: 'strategy-41',
      boolean: 'false'
    },
    {
      name: 'strategy-42',
      boolean: 'false'
    },
    {
      name: 'strategy-43',
      boolean: 'false'
    },
    {
      name: 'strategy-44',
      boolean: 'false'
    },
    {
      name: 'strategy-45',
      boolean: 'false'
    },
    {
      name: 'strategy-46',
      boolean: 'false'
    },
    {
      name: 'strategy-47',
      boolean: 'false'
    },
    {
      name: 'strategy-48',
      boolean: 'false'
    },
    {
      name: 'strategy-49',
      boolean: 'false'
    },
    {
      name: 'strategy-50',
      boolean: 'false'
    },
    {
      name: 'strategy-51',
      boolean: 'false'
    },
    {
      name: 'strategy-52',
      boolean: 'false'
    },
    {
      name: 'strategy-53',
      boolean: 'false'
    },
    {
      name: 'strategy-54',
      boolean: 'false'
    },
    {
      name: 'strategy-55',
      boolean: 'false'
    },
    {
      name: 'strategy-56',
      boolean: 'false'
    },
    {
      name: 'strategy-57',
      boolean: 'false'
    },
    {
      name: 'strategy-58',
      boolean: 'false'
    },
    {
      name: 'strategy-59',
      boolean: 'false'
    },
  ]);

  const [display1, setDisplay1] = useState<string>('false');
  const [display2, setDisplay2] = useState<string>('false');
  const [display3, setDisplay3] = useState<string>('false');
  const [display4, setDisplay4] = useState<string>('false');
  const [display5, setDisplay5] = useState<string>('false');
  const [display6, setDisplay6] = useState<string>('false');
  const [display7, setDisplay7] = useState<string>('false');
  const [display8, setDisplay8] = useState<string>('false');
  const [display9, setDisplay9] = useState<string>('false');
  const [display10, setDisplay10] = useState<string>('false');
  const [display11, setDisplay11] = useState<string>('false');
  const [display12, setDisplay12] = useState<string>('false');
  const [display13, setDisplay13] = useState<string>('false');
  const [display14, setDisplay14] = useState<string>('false');
  const [display15, setDisplay15] = useState<string>('false');
  const [display16, setDisplay16] = useState<string>('false');
  const [display17, setDisplay17] = useState<string>('false');
  const [display18, setDisplay18] = useState<string>('false');
  const [display19, setDisplay19] = useState<string>('false');
  const [display20, setDisplay20] = useState<string>('false');
  const [display21, setDisplay21] = useState<string>('false');
  const [display22, setDisplay22] = useState<string>('false');
  const [display23, setDisplay23] = useState<string>('false');
  const [display24, setDisplay24] = useState<string>('false');
  const [display25, setDisplay25] = useState<string>('false');
  const [display26, setDisplay26] = useState<string>('false');
  const [display27, setDisplay27] = useState<string>('false');
  const [display28, setDisplay28] = useState<string>('false');
  const [display29, setDisplay29] = useState<string>('false');
  const [display30, setDisplay30] = useState<string>('false');
  const [display31, setDisplay31] = useState<string>('false');
  const [display32, setDisplay32] = useState<string>('false');
  const [display33, setDisplay33] = useState<string>('false');
  const [display34, setDisplay34] = useState<string>('false');
  const [display35, setDisplay35] = useState<string>('false');
  const [display36, setDisplay36] = useState<string>('false');
  const [display37, setDisplay37] = useState<string>('false');
  const [display38, setDisplay38] = useState<string>('false');
  const [display39, setDisplay39] = useState<string>('false');
  const [display40, setDisplay40] = useState<string>('false');
  const [display41, setDisplay41] = useState<string>('false');
  const [display42, setDisplay42] = useState<string>('false');
  const [display43, setDisplay43] = useState<string>('false');
  const [display44, setDisplay44] = useState<string>('false');
  const [display45, setDisplay45] = useState<string>('false');
  const [display46, setDisplay46] = useState<string>('false');
  const [display47, setDisplay47] = useState<string>('false');
  const [display48, setDisplay48] = useState<string>('false');
  const [display49, setDisplay49] = useState<string>('false');
  const [display50, setDisplay50] = useState<string>('false');
  const [display51, setDisplay51] = useState<string>('false');
  const [display52, setDisplay52] = useState<string>('false');
  const [display53, setDisplay53] = useState<string>('false');
  const [display54, setDisplay54] = useState<string>('false');
  const [display55, setDisplay55] = useState<string>('false');
  const [display56, setDisplay56] = useState<string>('false');
  const [display57, setDisplay57] = useState<string>('false');
  const [display58, setDisplay58] = useState<string>('false');
  const [display59, setDisplay59] = useState<string>('false');

  const [isLoadingPermission, setIsLoadingPermission] = useState(true);

  const huddleSetElements = (elementsParams: Elements) => {
    setElements(elementsParams);
    setDisplay1(elements[0].boolean);
    setDisplay2(elements[1].boolean);
    setDisplay3(elements[2].boolean);
    setDisplay4(elements[3].boolean);
    setDisplay5(elements[4].boolean);
    setDisplay6(elements[5].boolean);
    setDisplay7(elements[6].boolean);
    setDisplay8(elements[7].boolean);
    setDisplay9(elements[8].boolean);
    setDisplay10(elements[9].boolean);
    setDisplay11(elements[10].boolean);
    setDisplay12(elements[11].boolean);
    setDisplay13(elements[12].boolean);
    setDisplay14(elements[13].boolean);
    setDisplay15(elements[14].boolean);
    setDisplay16(elements[15].boolean);
    setDisplay17(elements[16].boolean);
    setDisplay18(elements[17].boolean);
    setDisplay19(elements[18].boolean);
    setDisplay20(elements[19].boolean);
    setDisplay21(elements[20].boolean);
    setDisplay22(elements[21].boolean);
    setDisplay23(elements[22].boolean);
    setDisplay24(elements[23].boolean);
    setDisplay25(elements[24].boolean);
    setDisplay26(elements[25].boolean);
    setDisplay27(elements[26].boolean);
    setDisplay28(elements[27].boolean);
    setDisplay29(elements[28].boolean);
    setDisplay30(elements[29].boolean);
    setDisplay31(elements[30].boolean);
    setDisplay32(elements[31].boolean);
    setDisplay33(elements[32].boolean);
    setDisplay34(elements[33].boolean);
    setDisplay35(elements[34].boolean);
    setDisplay36(elements[35].boolean);
    setDisplay37(elements[36].boolean);
    setDisplay38(elements[37].boolean);
    setDisplay39(elements[38].boolean);
    setDisplay40(elements[39].boolean);
    setDisplay41(elements[40].boolean);
    setDisplay42(elements[41].boolean);
    setDisplay43(elements[42].boolean);
    setDisplay44(elements[43].boolean);
    setDisplay45(elements[44].boolean);
    setDisplay46(elements[45].boolean);
    setDisplay47(elements[46].boolean);
    setDisplay48(elements[47].boolean);
    setDisplay49(elements[48].boolean);
    setDisplay50(elements[49].boolean);
    setDisplay51(elements[50].boolean);
    setDisplay52(elements[51].boolean);
    setDisplay53(elements[52].boolean);
    setDisplay54(elements[53].boolean);
    setDisplay55(elements[54].boolean);
    setDisplay56(elements[55].boolean);
    setDisplay57(elements[56].boolean);
    setDisplay58(elements[57].boolean);
    setDisplay59(elements[58].boolean);
  };

  useEffect(() => {
    if (user.permission?.rule === 'coordenador') {
      elements[4].boolean = 'true';
      huddleSetElements(elements);
    }
    if (user.permission?.rule === 'professor') {
      elements[6].boolean = 'true';
      huddleSetElements(elements);
    }
    setIsLoadingPermission(false);
  }, []);
  return (
    <div className='App'>
      <Header />
      <BellContainer />
      <main className='main-container'>
        <Nav setElements={huddleSetElements} elements={elements} />
        <div className='content-containers'>
          <LoadContent isLoading={isLoadingPermission} />
          <div className='content' id={`display-${display1}`}>
            <Strategy id='strategy-1' display={display1} />
          </div>
          <div className='content' id={`display-${display2}`}>
            <Coordinator id='strategy-2' display={display2} />
          </div>
          <div className='content' id={`display-${display3}`}>
            <Teachers display={display3} id='strategy-3' />
          </div>
          <div className='content' id={`display-${display4}`}>
            <Rooms display={display4} id='strategy-4' />
          </div>
          <div className='content' id={`display-${display5}`}>
            <Students display={display5} id='strategy-5' />
          </div>
          <div className='content' id={`display-${display6}`}>
            <Plan id='strategy-6' display={display6} />
          </div>
          <div className='content' id={`display-${display7}`}>
            <RoomsTeacher id='strategy-7' />
          </div>
          <div className='content' id={`display-${display8}`}>
            <ConfigUser id='strategy-8' />
          </div>
          <div className='content' id={`display-${display9}`}>
            <WritingLevelRecordCoordinatorContent display={display9} id='strategy-9' />
          </div>
          <div className='content' id={`display-${display12}`}>
            <ElementarySchoolTeachingWorkPlan display={display12} id='strategy-12' />
          </div>
          <div className='content' id={`display-${display13}`}>
            <KindergartenSchoolTeachingWorkPlan display={display13} id='strategy-13' />
          </div>
          <div className='content' id={`display-${display14}`}>
            <NewKindergartenSchoolMonthlyPlan display={display14} id='strategy-14' />
          </div>
          <div className='content' id={`display-${display15}`}>
            <NewElementarySchoolMonthlyPlan display={display15} id='strategy-15' />
          </div>
          <div className='content' id={`display-${display16}`}>
            <KindergartenSchoolWeeklyPlan display={display16} id='strategy-16' />
          </div>
          <div className='content' id={`display-${display17}`}>
            <ElementarySchoolWeeklyPlan display={display17} id='strategy-17' />
          </div>
          <div className='content' id={`display-${display18}`}>
            <ReadingFluency display={display18} id='strategy-18' />
          </div>
          <div className='content' id={`display-${display19}`}>
            <ElementarySchoolHTTPI display={display19} id='strategy-19' />
          </div>
          <div className='content' id={`display-${display20}`}>
            <KindergartenSchoolHTTPI display={display20} id='strategy-20' />
          </div>
          <div className='content' id={`display-${display21}`}>
            <DigitalHTTPI display={display21} id='strategy-21' />
          </div>
          <div className='content' id={`display-${display22}`}>
            <ElementaryDigitalHTTPI display={display22} id='strategy-22' />
          </div>
          <div className='content' id={`display-${display23}`}>
            <KindergartenSchoolStudentReport display={display23} id='strategy-23' />
          </div>
          <div className='content' id={`display-${display24}`}>
            <ElementarySchoolStudentReport display={display24} id='strategy-24' />
          </div>
          <div className='content' id={`display-${display25}`}>
            <KindergartenSchoolATA display={display25} id='strategy-25' />
          </div>
          <div className='content' id={`display-${display26}`}>
            <NotificationManager display={display26} id='strategy-25' />
          </div>
          <div className='content' id={`display-${display27}`}>
            <StudentMonetaryAid display={display27} id='strategy-27' />
          </div>
          <div className='content' id={`display-${display28}`}>
            <WorkshopHTTPI display={display28} id='strategy-28' />
          </div>
          <div className='content' id={`display-${display29}`}>
            <WorkshopDigitalHTTPI display={display29} id='strategy-28' />
          </div>
          <div className='content' id={`display-${display30}`}>
            <WorkshopMonthlyPlan display={display30} id='strategy-28' />
          </div>
          <div className='content' id={`display-${display31}`}>
            <WorkshopWeeklyPlan display={display31} id='strategy-28' />
          </div>
          <div className='content' id={`display-${display32}`}>
            <WorkshopStudentReport display={display32} id='strategy-28' />
          </div>
          <div className='content' id={`display-${display33}`}>
            <ElementarySchoolProjects display={display33} id='strategy-28' />
          </div>
          <div className='content' id={`display-${display34}`}>
            <AddNewNutritionists display={display34} id='strategy-28' />
          </div>
          <div className='content' id={`display-${display35}`}>
            <AdmLegislation display={display35} id='strategy-28' />
          </div>
          <div className='content' id={`display-${display36}`}>
            <AdmATA display={display36}
              id='strategy-28'
              emptyText="click em (Adicionar ATA) para adicionar um nova ATA"
              title='ATA - Reunião Dos Pais'
              titleAdd='Adicionar Uma Nova ATA'
              titleEdit='Editar ATA'
              titleInfo='ATA - Reunião Dos Pais'
              type='parents-meeting'
            />
          </div>
          <div className='content' id={`display-${display37}`}>
            <AdmATA display={display37}
              id='strategy-28'
              emptyText="click em (Adicionar ATA) para adicionar um nova ATA"
              title='ATA - Reunião Gestão'
              titleAdd='Adicionar Uma Nova ATA'
              titleEdit='Editar ATA'
              titleInfo='ATA - Reunião Gestão'
              type='management-meeting'
            />
          </div>
          <div className='content' id={`display-${display38}`}>
            <AdmATA display={display38}
              id='strategy-28'
              emptyText="click em (Adicionar ATA) para adicionar um nova ATA"
              title='ATA - Reunião Funcionários'
              titleAdd='Adicionar Uma Nova ATA'
              titleEdit='Editar ATA'
              titleInfo='ATA - Reunião Funcionários'
              type='employee-meeting'
            />
          </div>
          <div className='content' id={`display-${display39}`}>
            <AdmATA display={display39}
              id='strategy-28'
              emptyText="click em (Adicionar ATA) para adicionar um nova ATA"
              title='ATA - Geral'
              titleAdd='Adicionar Uma Nova ATA'
              titleEdit='Editar ATA'
              titleInfo='ATA - Geral'
              type='general'
            />
          </div>
          <div className='content' id={`display-${display40}`}>
            <AdmSlides display={display40}
              id='strategy-28'
              emptyText="click em (Adicionar slide) para adicionar um novo slide"
              title='Slide - Eventos'
              titleAdd='Adicionar Slide'
              titleEdit='Editar Slide'
              titleInfo='Slide - Eventos'
              type='events'
            />
          </div>
          <div className='content' id={`display-${display41}`}>
            <AdmSlides display={display41}
              id='strategy-28'
              emptyText="click em (Adicionar slide) para adicionar um novo slide"
              title='Slide - Reuniões'
              titleAdd='Adicionar Slide'
              titleEdit='Editar Slide'
              titleInfo='Slide - Reuniões'
              type='meeting'
            />
          </div>
          <div className='content' id={`display-${display42}`}>
            <AdmSlides display={display42}
              id='strategy-28'
              emptyText="click em (Adicionar slide) para adicionar um novo slide"
              title='Slide - Geral'
              titleAdd='Adicionar Slide '
              titleEdit='Editar Slide'
              titleInfo='Slide - Geral'
              type='general'
            />
          </div>
          <div className='content' id={`display-${display43}`}>
          <AdmHours display={display43}
              id='strategy-28'
              emptyText="click em (Adicionar Documento) para adicionar um documento"
              title='Horários'
              titleAdd='Adicionar Documento'
              titleEdit='Editar Documento'
              titleInfo='Horários'
              type='general'
            />
          </div>
          <div className='content' id={`display-${display44}`}>
            <AdmCalendar display={display44}
                id='strategy-28'
                emptyText="click em (Adicionar Documento) para adicionar um documento"
                title='Calendários'
                titleAdd='Adicionar Documento'
                titleEdit='Editar Documento'
                titleInfo='Calendário'
                type='general'
              />
          </div>
          <div className='content' id={`display-${display45}`}>
            <MonitoringPlanSheetEducation
              id='monitoring-plan-sheet-education-display-content'
              display={display45}
            />
          </div>
          <div className='content' id={`display-${display46}`}>
            <PsychologistAddPermission display={display46} id='psychologist-add-permission' />
          </div>
          <div className='content' id={`display-${display47}`}>
            <PsychologistReportHome display={display47} id='psychologist-add-permission' />
          </div>
          <div className='content' id={`display-${display48}`}>
            <MathematicsDiagnosticAssessmentSecretarySecretary display={display48} id='psychologist-add-permission' />
          </div>
          <div className='content' id={`display-${display49}`}>
            <StudentExams display={display49} />
          </div>
          <div className='content' id={`display-${display50}`}>
            <WaitingStudents display={display50} id='' />
          </div>
          <div className='content' id={`display-${display51}`}>
            <NewSecretaryWritingHypothesis display={display51} />
          </div>
          <div className='content' id={`display-${display52}`}>
            <SecretaryDocument
              display={display52}
              emptyText='Clique em Adicionar documento para adicionar um documento'
              id={`display-${display52}`}
              title='Documentos - Projetos e Programas'
              titleAdd='Adicionar documento'
              titleEdit='Editar Documento'
              titleInfo='Projetos e Programas'
              type='projects-and-programs'
            />
          </div>
          <div className='content' id={`display-${display53}`}>
            <SecretaryDocument
              display={display53}
              emptyText='Clique em Adicionar documento para adicionar um documento'
              id={`display-${display53}`}
              title='Documentos - Leis Educacionais'
              titleAdd='Adicionar documento'
              titleEdit='Editar Documento'
              titleInfo='Leis Educacionais'
              type='educational-laws'
            />
          </div>
          <div className='content' id={`display-${display54}`}>
            <ResourceManagement display={display54} />
          </div>
          <div className='content' id={`display-${display55}`}>
              <EthnicityChart display={display55} />
          </div>
          <div className='content' id={`display-${display56}`}>
              <EthnicityChartGeneral display={display56} />
          </div>
          <div className='content' id={`display-${display57}`}>
              <ElementarySchoolJustificationForOneAbsence id={`display-${display57}`} display={display57} />
          </div>
          <div className='content' id={`display-${display58}`}>
              <KindergartenSchoolJustificationForOneAbsence id={`display-${display58}`} display={display58} />
          </div>
          <div className='content' id={`display-${display59}`}>
              <WorkshopJustificationForOneAbsence id={`display-${display59}`} display={display59} />
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
