/* eslint-disable prefer-const */
import axios from 'axios';
import config from '../../config/config';
import lscache from 'lscache';
import { type StudentReportPut, type StudentReportType } from '../types/StudentReport';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';

class UploadElementarySchoolJustificationForOneAbsenceApi {
    private readonly user = lscache.get('user') as UserClientProtocol & { token: string };
    private get href() {
        return config.apiUrl;
    }

    private get _date(): string {
        const date = new Date();

        return date.toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
            dateStyle: 'short',
            timeStyle: 'short'
        });
    }

    constructor() {
        try {
            axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
        } catch {
            //
        }
    }

    async post({
        file,
        classRoom,
        twoMonths,
        date,
        name,
        userName,
        userId
    }: any): Promise<StudentReportType[]> {
        let newElementaryJustificationForOneAbsence: StudentReportType[] = [];

        const formDate = new FormData();

        formDate.append('fileJustificationForOneAbsence', file);
        formDate.append('twoMonths', twoMonths);
        formDate.append('classRoom', classRoom);
        formDate.append('date', date);
        formDate.append('localDate', this._date);
        formDate.append('name', name);
        formDate.append('userName', userName);
        formDate.append('userId', userId);

        await axios({
            method: 'post',
            url: this.href + '/file/room/elementaryschool/JustificationForOneAbsence',
            data: formDate,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
            newElementaryJustificationForOneAbsence = response.data as StudentReportType[];
        });

        return newElementaryJustificationForOneAbsence;
    }

    async postDescription(
        decentWorkId: string,
        description: string,
        userId: string,
        userName: string
    ): Promise<StudentReportType | undefined> {
        let newElementaryJustificationForOneAbsence: StudentReportType | undefined;
        await axios
            .post(
                `${this.href}/description/elementaryschool/JustificationForOneAbsence/${decentWorkId}`,
                {
                    userId,
                    userName,
                    date: this._date,
                    description
                }
            )
            .then((response) => {
                newElementaryJustificationForOneAbsence = response.data as
                    | StudentReportType
                    | undefined;
            });

        return newElementaryJustificationForOneAbsence;
    }

    async deleteDescription(
        decentWorkId: string,
        descriptionId: string,
        userId: string
    ): Promise<StudentReportType | undefined> {
        let newElementaryJustificationForOneAbsence: StudentReportType | undefined;

        await axios
            .delete(
                `${this.href}/description/elementaryschool/JustificationForOneAbsence/${decentWorkId}/${descriptionId}/${userId}`
            )
            .then((response) => {
                newElementaryJustificationForOneAbsence = response.data as StudentReportType;
            });

        return newElementaryJustificationForOneAbsence;
    }

    async setChecking(HTTPIId: string): Promise<StudentReportType[]> {
        let newElementaryJustificationForOneAbsence: StudentReportType[] = [];

        await axios
            .put(
                `${this.href}/checkout/room/elementaryschool/JustificationForOneAbsence/${HTTPIId}`,
                {
                    userId: this.user.id,
                    userName: this.user.userName,
                    date: this._date
                }
            )
            .then((response) => {
                newElementaryJustificationForOneAbsence = response.data;
            });

        return newElementaryJustificationForOneAbsence;
    }

    async put({ file, name, studentReportId }: StudentReportPut): Promise<StudentReportType[]> {
        let newElementaryJustificationForOneAbsence: StudentReportType[] = [];

        const formData = new FormData();

        formData.append('fileJustificationForOneAbsence', file);
        formData.append('name', name);

        await axios({
            method: 'put',
            url: `${this.href}/file/room/elementaryschool/JustificationForOneAbsence/${studentReportId}`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
            newElementaryJustificationForOneAbsence = response.data as StudentReportType[];
        });

        return newElementaryJustificationForOneAbsence;
    }

    async get(): Promise<StudentReportType[]> {
        let newElementaryJustificationForOneAbsence: StudentReportType[] = [];

        await axios
            .get(this.href + '/file/room/elementaryschool/JustificationForOneAbsence')
            .then((response) => {
                newElementaryJustificationForOneAbsence = response.data as StudentReportType[];
            })
            .catch((e) => {
                //
            });

        return newElementaryJustificationForOneAbsence;
    }

    async delete(elementId: string, fileName: string): Promise<StudentReportType[]> {
        let newElementaryJustificationForOneAbsence: StudentReportType[] = [];

        await axios
            .delete(
                this.href + `/file/room/elementaryschool/JustificationForOneAbsence/${elementId}`,
                {
                    data: {
                        fileName
                    }
                }
            )
            .then((response) => {
                newElementaryJustificationForOneAbsence = response.data as StudentReportType[];
            })
            .catch((e) => {
                //
            });

        return newElementaryJustificationForOneAbsence;
    }

    async getData(teacherId: string): Promise<StudentReportType[]> {
        let newElementaryJustificationForOneAbsence: StudentReportType[] = [];

        await axios
            .get(
                this.href +
                    `/file/room/elementaryschool/JustificationForOneAbsence/get_by_user/${teacherId}`
            )
            .then((response) => {
                newElementaryJustificationForOneAbsence = response.data as StudentReportType[];
            })
            .catch((e) => {
                //
            });

        return newElementaryJustificationForOneAbsence;
    }
}

export default new UploadElementarySchoolJustificationForOneAbsenceApi();
