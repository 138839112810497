import React, { useEffect, useState } from 'react';
import { type SecretaryResourceType } from '../../../class/types/secretary-resource';
import Chart from 'react-apexcharts';
import './style.scss';

export interface SecretaryResourceProps {
  display: string
  resource: SecretaryResourceType[]
}

interface InformationType {
  notComplete: number
  complete: number
}

export default function SecretaryResourceGraph({display, resource}: SecretaryResourceProps): React.ReactElement {
  const [information, setInformation] = useState<InformationType>({
    notComplete: 0,
    complete: 0,
  })

  useEffect(() => {
    if (display === 'false' || resource.length === 0) {
      return;
    }

    let complete = 0;
    let notComplete = 0;
    resource.map(value => {
      if (value.status === 'Concluído') {
        complete++;
        return value;
      }
      notComplete++;
      return value;
    });
    information.complete = complete;
    information.notComplete = notComplete;

    setInformation({...information});
  }, [display, resource]);

  if (display === 'false') {
    return <span></span>;
  }

  return (
    <div className='secretary-resource'>
      <Chart
            className='graphic-content'
            type='pie'
            width={window.innerWidth * 0.50}
            height={window.innerHeight * 0.90}
            series={[information.notComplete, information.complete]}
            options={{
              labels: [`Não Concluído: ${information.notComplete}`, `Concluído: ${information.complete}`],
              colors: ['#FF2E00', '#118951'],
              legend: {
                fontSize: '20rem',
                offsetY: 70
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: ['#000000'],
                width: 0.5,
                dashArray: 0
              },
              chart: {
                width: '50px',
                height: '50px'
              },
              title: {
                align: 'center',
                margin: 50,
                style: {
                  fontSize: '3rem',
                },
                text: 'Gráfico do progresso de conclusão de ações '
              }
            }}
          />
    </div>
  );
}
