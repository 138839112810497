/* eslint-disable no-mixed-spaces-and-tabs */

import React from 'react';
import './style.scss';
import DisplayDocument from '../../../components/displayDocument';
import formatFileName from '../../../services/formatFileName';
import { FaEye, FaFileAlt } from 'react-icons/fa';
import { MdDateRange } from 'react-icons/md';
import CommentsElement from '../../../components/comments-element';
import uploadKindergartenSchoolJustificationForOneAbsenceApi from '../../../class/UploadKindergartenSchoolJustificationForOneAbsence/upload-kindergarten-school-justification-for-one-absence-api';
import uploadWorkshopJustificationForOneAbsenceApi from '../../../class/UploadWorkshopJustificationForOneAbsence/upload-workshop-justification-for-one-absence-api';

interface FormEditMonthlyPlanProps {
  setDisplayJustificationForOneAbsenceInfo: React.Dispatch<React.SetStateAction<boolean>>
  displayJustificationForOneAbsenceInfo: boolean
  setElements: React.Dispatch<React.SetStateAction<any[]>>
  setElement: React.Dispatch<React.SetStateAction<any>>
  elements: any[]
  element: any
}

export default function JustificationForOneAbsenceDisplayInformation({
  setDisplayJustificationForOneAbsenceInfo,
  displayJustificationForOneAbsenceInfo,
  setElement,
  setElements,
  elements,
  element
}: FormEditMonthlyPlanProps): JSX.Element {
  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  return (
    <div className={`elementary-school-justification-for-one-absence-information display-${String(displayJustificationForOneAbsenceInfo)}`}>
      <div className='header-form-describe-element-content'>
        <h1>
          Justificativa de Ausência
        </h1>
        <button onClick={() => {
          setDisplayJustificationForOneAbsenceInfo(false);
        }}>Voltar</button>
      </div>
      <div className='main-monthly-plan-display-information' id='main-monthly-plan-display-information'>
        <div className="decent-work-information">
          <h1><FaFileAlt className='file-description-icon' /> <span>{formatFileName(element.fileName)}</span></h1>
        </div>
        <div className="decent-work-information">
          <h1><MdDateRange className='file-description-icon' /> <span>{formatDate(element.date ?? ' ')}</span></h1>
        </div>
        <div className="decent-work-information">
          <h1><FaEye className='file-description-icon' /> <span>{element.checked ? `Visualizações: ${Object.keys(element.visualizations ?? { generic: 'generic' }).length ?? 1}` : 'Não visualizado'}</span></h1>
        </div>
        {!element.visualizations
          ? ''
          : (
            <div className="decent-work-information">
              <h1><span>Visualizações:</span></h1>
              <ul className='visualization-list'>
                {Object.keys(element.visualizations).map(key => (
                  <li key={key}>{element.visualizations[key].user} <span>visualizou em {element.visualizations[key].date}</span></li>
                ))}
              </ul>
            </div>
          )
        }
        <div className="decent-work-information">
          <h1>Emissor: <span>{element.issuer?.userName}</span></h1>
        </div>
        <div className="decent-work-information">
          <h1>Data de emissão: <span>{element.issuer?.emissionDate}</span></h1>
        </div>
        <div className='adm-hours-information-frame-content'>
          <DisplayDocument type={element.type} url={element.location.url} />
        </div>
        {!element.description
          ? ''
          : (
            <div className='description-content'>
              {!element.description
                ? ''
                : (
                  <div className='description-title'>
                    <div className='comment-title-content'>
                      <span className='tag'>Comentário</span>
                      {!element.description.issue
                        ? ''
                        : (
                          <>
                            <h1>{element.description?.issue?.userName}</h1>
                            <span className='date-content'>{element.description?.date}</span>
                          </>
                        )
                      }
                    </div>
                  </div>
                )}
              <p>
                {element.description?.content}
              </p>
            </div>
          )}
        {!element.comments
          ? ''
          : Object.keys(element.comments).map((key) => (
            <CommentsElement
              api={uploadWorkshopJustificationForOneAbsenceApi}
              comment={element.comments[key]}
              elementId={element.id}
              elements={elements}
              setElement={setElement}
              setElements={setElements}
              commentId={key}
              key={key}
            />
          ))}
      </div>
    </div>
  );
}
