import React, { useEffect, useState } from 'react'
import './style.scss';
import InputSelect from '../../components/input-select';
import RoomsApi from '../../class/Room/RoomsApi';
import Chart from 'react-apexcharts';
import { type RoomType } from '../../class/types/Room/Rooms-protocol';
import genEthnicityChartInfo from './services/genEthnicityChartInfo';
import LoadContent from '../../components/Load-content';

interface EthnicityChartProps {
  display: string
}

export default function EthnicityChart({ display }: EthnicityChartProps): React.ReactElement {
  const [rooms, setRooms] = useState<RoomType[]>([]);
  const [roomsByYear, setRoomsByYear] = useState<RoomType[]>([]);
  const [years, setYears] = useState<string[]>([]);
  const [ethnicityChartInfo, setEthnicityChartInfo] = useState<Record<
    'Branca'
    | 'Preta'
    | 'Parda'
    | 'Amarela'
    | 'Indígena', number> | undefined>();
  const [roomNameSelected, setRoomNameSelected] = useState<string>('');
  const [loadingRooms, setLoadingRooms] = useState<boolean>(false);

  const roomsSelect = (roomName: string, id?: string) => {
    if (!id) {
      setEthnicityChartInfo(undefined)
      return;
    }

    setRoomNameSelected(roomName);

    RoomsApi.getAllStudentByRoom(id).then(response => {
      const info = genEthnicityChartInfo(response);

      setEthnicityChartInfo(info);
    }).catch(e => {
      //
    })
  }

  const yearInputSelect = (year: string) => {
    filterRoomsByYear(year);
  }

  const filterRoomsByYear = (year: string) => {
    if (!year) {
      setRoomsByYear([]);
      setEthnicityChartInfo(undefined)
      return;
    }

    const roomsByYear = rooms.filter(room => {
      return room.date?.split('/')[2] === year || room.date?.split('/')[2] === year.slice(-2)
    });

    if (roomsByYear.length <= 0) {
      setRoomsByYear([]);
      setEthnicityChartInfo(undefined)
      return;
    }

    setRoomsByYear(roomsByYear)
  }

  const genYears = () => {
    const date = new Date();

    for (let i = 2022; i <= date.getFullYear() + 3; i++) {
      years.push(`${i}`);
    }

    setYears(years)
  }

  useEffect(() => {
    if (display === 'false' || rooms.length > 0) {
      return;
    }
    setLoadingRooms(true);
    RoomsApi.getAllRoomInfo().then(response => {
      setRooms(response);
      setRoomsByYear(response);
      setLoadingRooms(false)
    }).catch(e => [
      //
    ]);

    genYears();
  }, [display]);

  return (
    <div className='ethnicity-chart-content'>
      <div className='ethnicity-chart-content-header'>
        <h1>Gráfico de Etnia</h1>
      </div>
      <div className='ethnicity-chart-content-filters'>
        <InputSelect
          options={years}
          title='Escolha o ano letivo:'
          defaultValue={`${new Date().getFullYear()}`}
          cb={yearInputSelect}
        />
        {loadingRooms
          ? <LoadContent
              isLoading={loadingRooms}
              style={{
                button: {
                  width: 30,
                },
                container: {
                  width: 100,
                }
              }}
            />
          : <InputSelect
            options={[...roomsByYear.map(room => room.roomName)]}
            id={roomsByYear.map(room => room.id)}
            title='Escolha uma sala:'
            defaultValue='Escolha uma sala'
            cb={roomsSelect}
          />}

      </div>
      <div className='ethnicity-chart-content'>
        {!ethnicityChartInfo
          ? ''
          : <Chart
            className='graphic-content'
            type='pie'
            width={window.innerWidth * 0.60}
            height={window.innerHeight * 0.60}
            series={[ethnicityChartInfo?.Branca ?? 0, ethnicityChartInfo?.Parda ?? 0, ethnicityChartInfo?.Preta ?? 0, ethnicityChartInfo?.Amarela ?? 0, ethnicityChartInfo?.Indígena ?? 0]}
            options={{
              labels: ['Brancos', 'Pretos', 'Pardos', 'Amarelos', 'Indígenas'],
              colors: ['#FFF5EE', '#8B4513', '#F4A460', '#FFF8DC', '#FFDAB9'],
              legend: {
                fontSize: '20rem',
                offsetY: 70
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: ['#000000'],
                width: 0.5,
                dashArray: 0
              },
              chart: {
                width: '50px',
                height: '50px'
              },
              title: {
                align: 'center',
                margin: 50,
                style: {
                  fontSize: '3rem',
                },
                text: `Gráfico de Etnias - ${roomNameSelected}`
              }
            }}
          />}
      </div>
    </div>
  )
}
