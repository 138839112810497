import React, { useRef, useState } from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import TextualGenreCostumer from '../../../../class/new-writing-hypothesi/textual-genre/textual-genre-costumer';
import displayError from '../../../../class/services/displayError';
import { type TextualGenreType } from '../../../../class/types/new-writing-hypothesi/textual-genre';
import LoadContent from '../../../../components/Load-content';

interface FormAddTextualGenreProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  setTextualGenres: React.Dispatch<React.SetStateAction<TextualGenreType[]>>
  roomInfo: any
}

export default function FormAddTextualGenre({ display, setDisplay, roomInfo, setTextualGenres }: FormAddTextualGenreProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const input = useRef<HTMLInputElement | null>(null);

  const { id, roomName } = useParams();

  const close = () => {
    if (!input.current) {
      return;
    }

    input.current.value = '';
    setDisplay(false);
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!input.current || !id || !roomName) {
      return;
    }
    if (!input.current.value || input.current.value.length === 0) {
      displayError(input.current, 'Preencha o campo!');
      return;
    }

    const textualGenreCustumer = new TextualGenreCostumer();

    setIsLoading(true);
    textualGenreCustumer.post(id, roomInfo.twoMonths, input.current.value, roomName).then(response => {
      setIsLoading(false);
      setTextualGenres(response);
      close();
      if (!input.current) {
        return;
      }
      input.current.value = '';
    }).catch(e => {
      //
    });
  }

  return (
    <div className={`form-add-textual-genre display-${String(display)}`}>
      <div className='header-form-add-form-add-textual-genre'>
        <h1>
          Adicionar gênero textual ou uma nova avaliação
        </h1>
        <IoCloseOutline className='icon-close' onClick={() => {
          setDisplay(false);
        }} size='25' />
      </div>
      <form className='body-form-add-textual-genre' method='POST' action='/' onSubmit={onSubmit}>
        <div className='form-add-textual-genre-input-content'>
          <label htmlFor="input-form-add-textual-genre">Gênero textual ou avaliação:</label>
          <input type="text" id='input-form-add-textual-genre' placeholder='digite um titulo' ref={input} />
        </div>
        <div className='form-add-textual-genre-button-content'>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            )
            : (
              <>
                <button type='button' className='button-element button-cancel' onClick={close}>Cancelar</button>
                <button type='submit' className='button-element button-add'>Adicionar</button>
              </>
            )}
        </div>
      </form>
    </div>
  );
}
