import { type StudentType } from '../../../class/types/Student/student-factory-protocol';

export default function genEthnicityChartInfo(students: StudentType[]) {
  const info: Record<string, number> = {
    Branca: 0,
    Preta: 0,
    Parda: 0,
    Amarela: 0,
    Indígena: 0,
  }

  students.forEach(student => {
    Object.keys(info).forEach(key => {
      if (student.ethnicity?.includes(key)) {
        info[key]++;
      }
    });
  });

  return info;
}
