/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import FilterPlan from '../../../class/filterPlan';
import BackToTop from '../../../components/back-to-top/back-to-top';
import lscache from 'lscache';
import UserAPI from '../../../class/User/UserAPI';
import './style.scss';
import { type StudentReportType } from '../../../class/types/StudentReport';
import EmptyComponent from '../../../components/Empty-component';
import InputSelect from '../../../components/input-select';
import filterByTwoMonths from '../service/filterByTwoMonths';
import LoadContent from '../../../components/Load-content';
import uploadElementarySchoolJustificationForOneAbsenceApi from '../../../class/UploadElementarySchoolJustificationForOneAbsence/upload-elementary-school-justification-for-one-absence-api';
import ContentTableElementaryJustificationForOneAbsence from '../../../components/JustificationForOneAbsence';
import FormAddJustificationForOneAbsence from '../form-add-justification-for-one-absence';
import FormEditJustificationForOneAbsence from '../form-edit-justification-for-one-absence';
import JustificationForOneAbsenceDisplayInformation from '../justification-for-one-absence-display-information';
import ReadCheckingJustificationForOneAbsence from '../../../components/read-checking-justification-for-one-absence';
import FormDescribeJustificationForOneAbsence from '../form-description-justification-for-one-absence';
import uploadKindergartenSchoolJustificationForOneAbsenceApi from '../../../class/UploadKindergartenSchoolJustificationForOneAbsence/upload-kindergarten-school-justification-for-one-absence-api';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [kindergartenSchoolJustificationForOneAbsence, setKindergartenSchoolJustificationForOneAbsence] = useState<StudentReportType[]>([]);
  const [displayFormAddKindergartenJustificationForOneAbsence, setDisplayKindergartenJustificationForOneAbsence] = useState(false);
  const [displayJustificationForOneAbsenceInfo, setDisplayJustificationForOneAbsenceInfo] = useState(false);
  const [displayFormEditKindergartenStudentReport, setDisplayEditKindergartenStudentReport] = useState(false);
  const [elementId, setElementId] = useState<string>('');
  const [elementFiltered, setElementFiltered] = useState<StudentReportType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mainElement = useRef<any>();
  const [displayReadCheckingJustificationForOneAbsence, setDisplayReadCheckingJustificationForOneAbsence] = useState(false);
  const [displayDescription, setDisplayDescription] = useState(false);

  const [element, setElement] = useState<StudentReportType>(elementDefault);

  const huddleCreateNewElement = () => {
    setDisplayKindergartenJustificationForOneAbsence(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    let filterJustificationForOneAbsenceByTwoMonths = kindergartenSchoolJustificationForOneAbsence;

    if (inputs.item(2).value !== 'Todos' && inputs.item(2).value !== 'escolha um bimestre') {
      filterJustificationForOneAbsenceByTwoMonths = filterByTwoMonths(kindergartenSchoolJustificationForOneAbsence, inputs.item(2).value)
    }

    const filterPlan = new FilterPlan([
      inputs.item(0),
      inputs.item(1),
      inputs.item(3),
      inputs.item(4),
    ], filterJustificationForOneAbsenceByTwoMonths);

    setElementFiltered(filterPlan.searchPlans());
  };
  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user || !user.id) {
      return;
    }

    if (display === 'false' || kindergartenSchoolJustificationForOneAbsence.length > 0) {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      uploadKindergartenSchoolJustificationForOneAbsenceApi.get().then((response) => {
        setIsLoading(false);
        if (permission === 'professor') {
          const studentReport = response.filter(value => value.issuer?.userId === user.id)
          setKindergartenSchoolJustificationForOneAbsence(studentReport);
          setElementFiltered(studentReport);
          return;
        }

        setKindergartenSchoolJustificationForOneAbsence(response);
        setElementFiltered(response);
      }).catch((e) => {
        //
      });
    }).catch(e => {
      //
    })
  }, [display]);

  useEffect(() => {
    setElementFiltered(kindergartenSchoolJustificationForOneAbsence);
  }, [kindergartenSchoolJustificationForOneAbsence])

  return (
    <div className='table-container-new-elementary-school-Justification-for-one-absence'>
      <h1 className='title-table'>Ensino Infantil - Justificativa de Ausência</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <FormDescribeJustificationForOneAbsence
        displayFormDescribeJustificationForOneAbsence={displayDescription}
        setDisplayFormDescribeJustificationForOneAbsence={setDisplayDescription}
        elementId={elementId}
        kindergartenSchoolJustificationForOneAbsence={kindergartenSchoolJustificationForOneAbsence}
        setKindergartenSchoolJustificationForOneAbsence={setKindergartenSchoolJustificationForOneAbsence}
      />
      <ReadCheckingJustificationForOneAbsence
        displayReadChecking={displayReadCheckingJustificationForOneAbsence}
        elementId={elementId}
        setDisplayReadChecking={setDisplayReadCheckingJustificationForOneAbsence}
        setElementaryJustificationForOneAbsence={setKindergartenSchoolJustificationForOneAbsence}
        type='kindergarten-school'
      />
      <FormAddJustificationForOneAbsence
        displayFormAddStudentReport={displayFormAddKindergartenJustificationForOneAbsence}
        setDisplayFormAddJustificationForOneAbsence={setDisplayKindergartenJustificationForOneAbsence}
        setKindergartenSchoolJustificationForOneAbsence={setKindergartenSchoolJustificationForOneAbsence}
        kindergartenSchoolJustificationForOneAbsence={kindergartenSchoolJustificationForOneAbsence}
      />
      <FormEditJustificationForOneAbsence
        displayFormEditJustificationForOneAbsence={displayFormEditKindergartenStudentReport}
        setDisplayFormEditJustificationForOneAbsence={setDisplayEditKindergartenStudentReport}
        setKindergartenSchoolJustificationForOneAbsence={setKindergartenSchoolJustificationForOneAbsence}
        setDisplayEditElement={setDisplayEditKindergartenStudentReport}
        elementId={elementId}
      />
      <JustificationForOneAbsenceDisplayInformation
        displayJustificationForOneAbsenceInfo={displayJustificationForOneAbsenceInfo}
        setDisplayJustificationForOneAbsenceInfo={setDisplayJustificationForOneAbsenceInfo}
        setElements={setKindergartenSchoolJustificationForOneAbsence}
        setElement={setElement}
        elements={kindergartenSchoolJustificationForOneAbsence}
        element={element}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar Justificativa de Ausência</button>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='name-content'>
          <div className='search-input-content input-content'>
            <input type='text' placeholder='professor' />
          </div>
          <div className='search-input-content input-content'>
            <input type='text' placeholder='nome da sala' />
          </div>
          <InputSelect
            options={[
              'Todos',
              '1º Bimestre',
              '2º Bimestre',
              '3º Bimestre',
              '4º Bimestre'
            ]}
            title=''
            defaultValue='escolha um bimestre'
          />
        </div>
        <div className='date-content'>
          <div className='search-input-content input-content input-date-content-one input-data'>
            <input type='date' />
          </div>
          <h2>A</h2>
          <div className='search-input-content input-content input-date-content-two input-data'>
            <input type='date' />
          </div>
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Professor</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li'>Bimestre</li>
          <li className='header-table-ul-li visualization-element'>Visualizado</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '10rem'
              }
            }}
          />
          : (
            display === 'true'
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar Justificativa de Ausência) para adicionar um nova Justificativa de Ausência</EmptyComponent>
                  : elementFiltered?.map((kindergartenSchoolJustificationForOneAbsence, index: number) => (
                    <ContentTableElementaryJustificationForOneAbsence
                      index={index}
                      elementarySchoolJustificationForOneAbsence={kindergartenSchoolJustificationForOneAbsence}
                      setDisplayJustificationForOneAbsence={setDisplayJustificationForOneAbsenceInfo}
                      setDisplayChecking={setDisplayReadCheckingJustificationForOneAbsence}
                      setDisplayEditElement={setDisplayEditKindergartenStudentReport}
                      setElementId={setElementId}
                      setElement={setElement}
                      displayDescription={displayDescription}
                      setDisplayDescription={setDisplayDescription}
                      setElementarySchoolJustificationForOneAbsence={setKindergartenSchoolJustificationForOneAbsence}
                      type={'kindergartenSchool'}
                    />
                  ))
              )
              : ''
          )}
      </main>
    </div>
  );
}
